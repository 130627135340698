<i18n src="./resources/locales.json"></i18n>

<template>
  <v-app>
    <v-app-bar
      color="primaryVariant1"
      app
      clipped-left
      flat
      v-if="isLoggedIn()"
    >
      <v-toolbar-title class="primaryVariant2--text">{{
        $t('systemAdministration')
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon
        color="primaryVariant2"
        @click="$router.push({ path: 'settings' })"
      >
        mdi-cog-outline
      </v-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      flat
      clipped
      fixed
      :mini-variant.sync="mini"
      permanent
      v-if="isLoggedIn()"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar
          :color="getAvatarBackgroundColor(getUserStorageFullName)"
        >
          <span class="primaryVariant2--text">{{
            getAvatarText(getUserStorageFullName)
          }}</span>
        </v-list-item-avatar>

        <v-list-item-title>{{ getUserStorageFullName }}</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list dense>
        <v-list-item
          class="selected-tile"
          active-class="selected-tile-active"
          v-for="item in getNavigationItems('Client')"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="$router.push({ path: item.path })">
            <v-list-item-title class="text-wrap">{{
              $t(item.title)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="primaryVariant1"
                >mdi-text-box-multiple-outline</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t('workOrders')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('Order')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push({ path: item.path })">
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-item
          class="selected-tile"
          active-class="selected-tile-active"
          v-for="item in getNavigationItems('Worker')"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="$router.push({ path: item.path })">
            <v-list-item-title class="text-wrap">{{
              $t(item.title)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-group :value="false" no-action v-if="isAdmin()">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="primaryVariant1">mdi-file-chart-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t('reports')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('Report')"
            :key="item.title"
            link
          >
            <v-list-item-icon v-if="item.path">
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              v-if="item.path"
              @click="$router.push({ path: item.path })"
            >
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('ServiceSubItems')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push({ path: item.path })">
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-group :value="false" no-action v-if="isAdmin()">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="primaryVariant1">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{
                $t('configuration')
              }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('Engine')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push({ path: item.path })">
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('Service')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push({ path: item.path })">
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-list-item
            class="selected-tile"
            active-class="selected-tile-active"
            v-for="item in getNavigationItems('Order Configuration')"
            :key="item.title"
            link
          >
            <v-list-item-icon>
              <v-icon color="primaryVariant1">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="$router.push({ path: item.path })">
              <v-list-item-title class="text-wrap">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-item
          class="selected-tile"
          active-class="selected-tile-active"
          link
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon color="primaryVariant1">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="$router.push({ path: 'login' })">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>

    <ContextMenu />
    <WorkersAssigmentListModal />
    <RectifierOrderDetailModal />
    <PrintOrder />
    <PrintSparePart />
    <PrintFinancialReport />
    <PrintQualityAssuranceForm />
    <OrderDetailModalInfo />
    <OrderStatusSelectionModal />
    <ReceiveSparePartsModal />
    <MessageModal />
    <StatusMessageSnackbar />
    <QualityAssuranceModal />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContextMenu from '@/components/reusable-components/context-menu/ContextMenu.vue';
import WorkersAssigmentListModal from '@/components/order/workers-assignment-list-modal/WorkersAssigmentListModal.vue';
import RectifierOrderDetailModal from '@/components/order/rectifier-order-detail-modal/RectifierOrderDetailModal.vue';
import OrderStatusSelectionModal from '@/components/order-status/order-status-selection-modal/OrderStatusSelectionModal.vue';
import PrintOrder from '@/components/reusable-components/printer/PrintOrder.vue';
import PrintSparePart from '@/components/reusable-components/printer/PrintSparePart.vue';
import PrintFinancialReport from '@/components/reusable-components/printer/PrintFinancialReport.vue';
import PrintQualityAssuranceForm from '@/components/reusable-components/printer/PrintQualityAssuranceForm.vue';
import OrderDetailModalInfo from '@/components/order/order-detail-modal-info/OrderDetailModalInfo.vue';
import ReceiveSparePartsModal from '@/components/order/receive-spare-parts-modal/ReceiveSparePartsModal.vue';
import MessageModal from '@/components/reusable-components/message-modal/MessageModal.vue';
import StatusMessageSnackbar from '@/components/reusable-components/status-message-snackbar/StatusMessageSnackbar.vue';
import QualityAssuranceModal from '@/components/order/quality-assurance-modal/QualityAssuranceModal.vue';
import {
  CLIENT_ITEMS,
  ENGINE_ITEMS,
  SERVICE_ITEMS,
  ORDER_CONFIGURATION_ITEMS,
  ORDER_ITEMS,
  WORKER_ITEMS,
  REPORT_ITEMS,
  SERVICE_REPORT_SUB_ITEMS
} from '@/constants/NavigationConstants';
import {
  ADMIN_ROLE_ID,
  SUPERVISOR_ROLE_ID,
  CASHIER_ROLE_ID
} from '@/constants/ConfigurationConstants';

import roles from './mixins/roles.js';

export default {
  name: 'App',

  components: {
    ContextMenu,
    WorkersAssigmentListModal,
    RectifierOrderDetailModal,
    OrderStatusSelectionModal,
    PrintOrder,
    PrintSparePart,
    PrintFinancialReport,
    PrintQualityAssuranceForm,
    OrderDetailModalInfo,
    ReceiveSparePartsModal,
    MessageModal,
    StatusMessageSnackbar,
    QualityAssuranceModal
  },

  data() {
    return {
      drawer: false,
      mini: true,
      subServicesGroupOpened: false
    };
  },

  computed: {
    ...mapGetters('languageStorage', ['getStoredLanguage']),
    ...mapGetters('userStorage', ['getUserStorageFullName']),
    ...mapGetters('roles', ['getAdminId', 'getSupervisorId', 'getCashierId'])
  },

  mixins: [roles],

  created() {
    this.retrieveAllCylinders();
    this.retrieveAllCylinderArrangements();
    this.retrieveAllBrands();
    this.retrieveAllRoles();
    this.retrieveAllWorkers();
    this.retrieveAllServices();
    this.retrieveStoredLanguage();
    this.loggedUser();
    this.setThemeSettings();
    this.setLanguage();
  },

  methods: {
    ...mapActions('cylinders', [
      'retrieveAllCylinders',
      'retrieveAllCylinderArrangements'
    ]),
    ...mapActions('brands', ['retrieveAllBrands']),
    ...mapActions('roles', ['retrieveAllRoles']),
    ...mapActions('services', ['retrieveAllServices']),
    ...mapActions('workers', ['retrieveAllWorkers']),
    ...mapActions('userStorage', ['loggedUser']),
    ...mapActions('languageStorage', ['retrieveStoredLanguage']),
    ...mapActions('userStorage', ['logoutUser']),

    isLoggedIn() {
      return window.localStorage.getItem('token');
    },

    setThemeSettings() {
      let settings = window.localStorage.getItem('settings');

      if (settings) {
        settings = JSON.parse(settings);
        this.$vuetify.theme.dark = settings.darkMode;
      }
    },

    setLanguage() {
      if (this.getStoredLanguage !== null) {
        this.$root.$i18n.locale = this.getStoredLanguage;
      }
    },

    logout() {
      this.logoutUser();
    },

    getNavigationItems(navigationItem) {
      if (navigationItem === 'Client') {
        return this._getRoleItems(CLIENT_ITEMS);
      }
      if (navigationItem === 'Engine') {
        return this._getRoleItems(ENGINE_ITEMS);
      }
      if (navigationItem === 'Service') {
        return this._getRoleItems(SERVICE_ITEMS);
      }
      if (navigationItem === 'Order Configuration') {
        return this._getRoleItems(ORDER_CONFIGURATION_ITEMS);
      }
      if (navigationItem === 'Order') {
        return this._getRoleItems(ORDER_ITEMS);
      }
      if (navigationItem === 'Worker') {
        return this._getRoleItems(WORKER_ITEMS);
      }
      if (navigationItem === 'Report') {
        return REPORT_ITEMS;
      }
      if (navigationItem === 'ServiceSubItems') {
        return SERVICE_REPORT_SUB_ITEMS;
      }
    },

    _getRoleItems(items) {
      const adminId = ADMIN_ROLE_ID;
      const supervisorId = SUPERVISOR_ROLE_ID;
      const cashierId = CASHIER_ROLE_ID;

      if (this.isAdmin()) {
        return items.filter(item => item.allowedRoles.includes(adminId));
      }
      if (this.isSupervisor()) {
        return items.filter(item => item.allowedRoles.includes(supervisorId));
      }
      if (this.isCashier()) {
        return items.filter(item => item.allowedRoles.includes(cashierId));
      }
    },

    getAvatarBackgroundColor(clientCI) {
      const stringForColor =
        clientCI.toString().length > 5
          ? clientCI.toString().substring(0, 5)
          : clientCI.toString().charAt(0);
      return this._getColorFromString(stringForColor);
    },

    getAvatarText(name) {
      let result = '';
      const firstName = name.trim().split(' ')[0];
      const lastName = name.trim().split(' ')[1];

      if (firstName) result += firstName.charAt(0);
      if (lastName) result += lastName.charAt(0);
      else if (firstName.length > 1) result += firstName.charAt(1);

      return result.toUpperCase();
    },

    _getHueFromString(string = '') {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 20) - hash);
      }
      return hash % 360;
    },

    _getColorFromString(string) {
      const saturation = 65;
      const lightness = 70;
      const hue = this._getHueFromString(string);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
