<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ boardTitle }}</h1>
    <div class="board-container mt-10 ml-16 mb-n12 mr-16">
      <Container orientation="horizontal">
        <Draggable>
          <Container orientation="horizontal">
            <Draggable>
              <v-container
                fluid
                fill-height
                :style="getOrderStatusStyles(getInitialOrderStatus.color)"
              >
                <v-card outlined class="order-status-card">
                  <v-card-title class="pb-0">
                    <div>
                      {{ getInitialOrderStatus.name }}
                    </div>
                    <v-icon
                      class="ml-3"
                      color="primary"
                      dark
                      @click="
                        openBoardFilterMenu($event, getInitialOrderStatus.id)
                      "
                    >
                      mdi-filter-variant
                    </v-icon>
                  </v-card-title>

                  <v-card-title class="py-0">
                    <v-subheader>
                      -
                      {{
                        getLabelSelectedFilter(getInitialOrderStatus.id)
                      }}</v-subheader
                    >
                  </v-card-title>
                  <Container
                    group-name="col"
                    @drop="e => onDropCard(getInitialOrderStatus.id, e)"
                    :get-child-payload="
                      getCardPayload(getInitialOrderStatus.id)
                    "
                    drag-class="card-ghost"
                    drop-class="card-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                  >
                    <Draggable
                      v-for="order in getOrdersByOrderStatusId(
                        getInitialOrderStatus.id
                      )"
                      :key="order.id"
                      class="pa-3"
                      :empty-insert-threshhold="7000"
                    >
                      <v-card
                        outlined
                        @contextmenu.prevent="openContextMenu($event, order)"
                      >
                        <Order
                          :key="order.id"
                          :order="order"
                          :isShowDotsContextMenu="false"
                          :isShowIcon="true"
                          :showContextMenuOptions="true"
                          :currentOrHistorical="CURRENT"
                          :isShowForUnassignedServiceCalendar="false"
                        />
                      </v-card>
                    </Draggable>
                  </Container>
                </v-card>
              </v-container>
            </Draggable>
          </Container>
        </Draggable>
        <Container
          orientation="horizontal"
          @drop="onDropColumn($event)"
          :drop-placeholder="upperDropPlaceholderOptions"
          height="5000px"
        >
          <div
            v-if="!areInProgressOrderStatuses"
            class="progress-status-orders"
          >
            <strong class="no-progress-order-statuses-text">{{
              $t('thereAreNoIntermediateStates')
            }}</strong>
          </div>
          <Draggable
            v-for="orderStatus in getAllInProgressOrderStatuses"
            :key="orderStatus.id"
            :empty-insert-threshhold="7000"
          >
            <v-container
              fluid
              fill-height
              :style="getOrderStatusStyles(orderStatus.color)"
            >
              <v-card outlined class="order-status-card">
                <v-card-title class="pb-0">
                  <div class="order-status-title">
                    {{ orderStatus.name }}
                  </div>
                  <v-icon
                    class="ml-3"
                    color="primary"
                    dark
                    @click="openBoardFilterMenu($event, orderStatus.id)"
                  >
                    mdi-filter-variant
                  </v-icon>
                </v-card-title>

                <v-card-title class="py-0">
                  <v-subheader>
                    -
                    {{ getLabelSelectedFilter(orderStatus.id) }}</v-subheader
                  >
                </v-card-title>
                <Container
                  group-name="col"
                  @drop="e => onDropCard(orderStatus.id, e)"
                  :get-child-payload="getCardPayload(orderStatus.id)"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                >
                  <Draggable
                    v-for="order in getOrdersByOrderStatusId(orderStatus.id)"
                    :key="order.id"
                    class="pa-3"
                  >
                    <v-card
                      outlined
                      @contextmenu.prevent="openContextMenu($event, order)"
                    >
                      <Order
                        :key="order.id"
                        :order="order"
                        :isShowDotsContextMenu="false"
                        :isShowIcon="true"
                        :showContextMenuOptions="true"
                        :currentOrHistorical="CURRENT"
                        :isShowForUnassignedServiceCalendar="false"
                      />
                    </v-card>
                  </Draggable>
                </Container>
              </v-card>
            </v-container>
          </Draggable>
        </Container>
        <Draggable height="5000px">
          <Container orientation="horizontal" height="5000px">
            <Draggable :empty-insert-threshhold="700000" height="5000px">
              <v-container
                fluid
                fill-height
                :style="getOrderStatusStyles(getFinalOrderStatus.color)"
                height="5000px"
              >
                <v-card outlined class="order-status-card" height="5000px">
                  <v-card-title>
                    <div>
                      {{ getFinalOrderStatus.name }}
                    </div>
                  </v-card-title>
                  <Container
                    group-name="col"
                    @drop="e => onDropCard(getFinalOrderStatus.id, e)"
                    :get-child-payload="getCardPayload(getFinalOrderStatus.id)"
                    drag-class="card-ghost"
                    drop-class="card-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                    height="5000px"
                    :empty-insert-threshhold="7000"
                  >
                    <Draggable
                      v-for="order in getOrdersByOrderStatusId(
                        getFinalOrderStatus.id
                      )"
                      :key="order.id"
                      class="pa-3"
                      :empty-insert-threshhold="7000"
                    >
                      <v-card
                        outlined
                        @contextmenu.prevent="openContextMenu($event, order)"
                      >
                        <Order
                          :key="order.id"
                          :order="order"
                          :isShowDotsContextMenu="false"
                          :isShowIcon="true"
                          :showContextMenuOptions="true"
                          :currentOrHistorical="CURRENT"
                          :isShowForUnassignedServiceCalendar="false"
                        />
                      </v-card>
                    </Draggable>
                  </Container>
                </v-card>
              </v-container>
            </Draggable>
          </Container>
        </Draggable>
      </Container>
    </div>
    <OrderDeliverOptionModal ref="deliverOptionModal" />
    <OrderStatusChangeConfirmationModal ref="confirmationChangeModal" />
    <DeleteConfirmModal
      ref="deleteOrderModal"
      @confirm-action="deleteOrderConfirm"
    />
    <LoadingMask :isLoading="isLoading" />
    <ConfirmationModal
      ref="confirmationModal"
      @confirm-action="confirmationModalConfirmAction"
    />
    <ManageJobListModal ref="manageJobListModal" />
    <BoardFilterMenu ref="boardFilterMenu" @change-option="makeFilter" />
    <PrintConfigurationModal ref="printConfirmationModal" />
  </v-container>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Order from '@/components/reusable-components/order/Order.vue';
import DeleteConfirmModal from '@/components/reusable-components/delete-confirm-modal/DeleteConfirmModal.vue';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import OrderDeliverOptionModal from '@/components/order/order-deliver-option-modal/OrderDeliverOptionModal.vue';
import OrderStatusChangeConfirmationModal from '@/components/order/order-status-change-confirmation-modal/OrderStatusChangeConfirmationModal.vue';
import ConfirmationModal from '@/components/reusable-components/confirmation-modal/ConfirmationModal.vue';
import ManageJobListModal from '@/components/reusable-components/manage-job-list-modal/ManageJobListModal.vue';
import PrintConfigurationModal from '@/components/reusable-components/printer/PrintConfigurationModal.vue';
import BoardFilterMenu from '@/components/reusable-components/board-filter-menu/BoardFilterMenu.vue';
import { CURRENT, FIVE_LAST_DAYS } from '@/constants/ConfigurationConstants';

import roles from '../../../mixins/roles';
import { CANCEL_CREDIT } from '@/constants/ContextMenuConstants';
import OrderMixin from '../../../mixins/OrderMixin';

export default {
  name: 'Board',

  components: {
    Container,
    Draggable,
    Order,
    DeleteConfirmModal,
    LoadingMask,
    ConfirmationModal,
    OrderDeliverOptionModal,
    OrderStatusChangeConfirmationModal,
    ManageJobListModal,
    BoardFilterMenu,
    PrintConfigurationModal
  },

  props: ['boardTitle', 'businessId'],

  data() {
    return {
      upperDropPlaceholderOptions: {
        className: 'cards-drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      orderToContextMenu: null,
      isConfirmModalAction: null,
      orderIdToPrint: null,
      CURRENT,
      listOrderStatusesFilter: []
    };
  },

  mixins: [roles, OrderMixin],

  computed: {
    ...mapGetters('orders', ['getOrdersByOrderStatusId']),
    ...mapGetters('orderStatuses', [
      'getAllInProgressOrderStatuses',
      'getInitialOrderStatus',
      'getFinalOrderStatus',
      'getOrderStatusById'
    ]),
    ...mapGetters('loading', ['getIsOrderStatusLoading', 'getIsOrderLoading']),

    areInProgressOrderStatuses() {
      return this.getAllInProgressOrderStatuses.length > 0;
    },

    isLoading() {
      let isLoading = true;
      if (!this.getIsOrderStatusLoading && !this.getIsOrderLoading) {
        isLoading = false;
      }
      return isLoading;
    }
  },

  watch: {
    getInitialOrderStatus: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setListOrderStatusesFilter();
        }
      },
      deep: true
    },

    getAllInProgressOrderStatuses: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setListOrderStatusesFilter();
        }
      },
      deep: true
    }
  },

  created() {
    this.retrieveInitialOrderStatus();
    this.retrieveFinalOrderStatus();
    this.retrieveAllBusinessOrdersFromLastFiveAbleDays(this.businessId);
    this.retrieveAllBusinessAndSpecialOrderStatuses(this.businessId);
  },

  methods: {
    ...mapActions('orders', [
      'retrieveAllBusinessOrdersFromLastFiveAbleDays',
      'updateOrderStatusOfOrder',
      'removeOrder',
      'enableOrder',
      'cancelOrder',
      'updateIsLoanOfAnOrderAsFalse',
      'retrieveFilteredBusinessOrdersByOrderStatus'
    ]),

    ...mapActions('orderStatuses', [
      'retrieveAllBusinessAndSpecialOrderStatuses',
      'updateOrderStatuses',
      'retrieveInitialOrderStatus',
      'retrieveFinalOrderStatus'
    ]),

    onDropColumn(dropResult) {
      const { removedIndex, addedIndex, payload } = dropResult;
      if ((!removedIndex && !addedIndex) || removedIndex === addedIndex) return;

      const orderStatuses = [...this.getAllInProgressOrderStatuses];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = orderStatuses.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        orderStatuses.splice(addedIndex, 0, itemToAdd);
      }

      let position = 0;
      orderStatuses.forEach(os => {
        os.position = position;
        position++;
      });

      this.updateOrderStatuses(orderStatuses);
    },

    onDropCard(columnOrderStatusId, dropResult) {
      const { removedIndex, addedIndex, payload } = dropResult;
      if (removedIndex === null && addedIndex === null) return;

      const orders = [...this.getOrdersByOrderStatusId(columnOrderStatusId)];
      const orderStatus = this.getOrderStatusById(columnOrderStatusId);
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = orders.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null && removedIndex === null) {
        if (
          itemToAdd.orderStatusId === this.getInitialOrderStatus.id &&
          (!this.verifyAllAssignedServices(itemToAdd) ||
            !this.isAllSparePartsRecieved(itemToAdd))
        ) {
          itemToAdd.orderStatusId = columnOrderStatusId;
          itemToAdd.orderStatus.name = orderStatus.name;
          itemToAdd.orderStatus.color = orderStatus.color;
          let confirmationOptionTitle = this.$t(
            'youAreAboutToChangeTheStatusOfTheOrderWithoutAssigningAllTheJobsAndWithoutRecievingAllSpareParts'
          );
          let isFromBoard = true;
          this.$refs.confirmationChangeModal.open(
            confirmationOptionTitle,
            isFromBoard,
            Object.assign({}, itemToAdd)
          );
        } else {
          if (columnOrderStatusId === this.getFinalOrderStatus.id) {
            if (this.isAllServicesFinished(itemToAdd)) {
              itemToAdd.orderStatusId = columnOrderStatusId;
              itemToAdd.orderStatus.name = orderStatus.name;
              itemToAdd.orderStatus.color = orderStatus.color;
              orders.splice(addedIndex, 0, itemToAdd);
              let isFromBoard = true;
              this.$refs.deliverOptionModal.open(
                isFromBoard,
                false,
                Object.assign({}, itemToAdd)
              );
            } else {
              let confirmationOptionTitle = this.$t(
                'youAreAboutToChangeToFinalStatusOfTheOrderWithoutFinishingAllServices'
              );
              itemToAdd.orderStatusId = columnOrderStatusId;
              itemToAdd.orderStatus.name = orderStatus.name;
              itemToAdd.orderStatus.color = orderStatus.color;
              let isFromBoard = true;
              this.$refs.confirmationChangeModal.open(
                confirmationOptionTitle,
                isFromBoard,
                Object.assign({}, itemToAdd)
              );
            }
          } else {
            itemToAdd.orderStatusId = columnOrderStatusId;
            itemToAdd.orderStatus.name = orderStatus.name;
            itemToAdd.orderStatus.color = orderStatus.color;
            orders.splice(addedIndex, 0, itemToAdd);
            this.updateOrderStatusOfOrder({
              orderToUpdate: itemToAdd,
              date: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ')
            });
          }
        }
      }
    },

    getCardPayload(orderStatusId) {
      return index => {
        return this.getOrdersByOrderStatusId(orderStatusId)[index];
      };
    },

    verifyAllAssignedServices(order) {
      let totalServices = 0;
      order.detail.services.forEach(service => {
        if (service.subServices.length === 0) {
          totalServices += 1;
        } else {
          totalServices += service.subServices.length;
        }
      });
      return totalServices === order.assignedServices.length;
    },

    isAllSparePartsRecieved(order) {
      return order.detail.item.spareParts.every(sp => sp.received === true);
    },

    getOrderStatusStyles(orderStatusColor) {
      return {
        '--column-color': orderStatusColor
      };
    },

    isAbleToPrintSpareParts(order) {
      let isAble = true;
      if (order && order.detail && order.detail.item.spareParts.length > 0) {
        if (
          order.detail.item.spareParts.every(
            sparePart => sparePart.price !== ''
          )
        ) {
          isAble = false;
        }
      }
      if (order && order.detail && order.detail.item.spareParts.length === 0) {
        isAble = false;
      }
      return isAble;
    },

    isOrderCancelled(order) {
      return order.canceled;
    },

    editOrder(data) {
      this.$root.$emit(
        'open-rectifier-order-detail-modal',
        Object.assign({}, data.order)
      );
    },

    printOrder(orderToContextMenu, isWithDiscount) {
      this.$root.$emit('print-order-pdf', orderToContextMenu, isWithDiscount);
    },

    printSparePart(data) {
      this.$root.$emit('print-spare-part-pdf', data.order.id);
    },

    assignWorker(data) {
      this.$root.$emit('open-worker-assigment-modal', data.order);
    },

    receiveSpareParts(data) {
      this.$root.$emit(
        'open-receive-spare-parts-modal',
        Object.assign({}, data.order)
      );
    },

    deleteOrder(data) {
      this.$refs.deleteOrderModal.open(
        Object.assign({ message: this.$t('sureToDeleteThisOrder') }, data.order)
      );
    },

    deleteOrderConfirm(order) {
      this.removeOrder(order);
    },

    async cancelOrderFromMenu(data) {
      try {
        await this.cancelOrder(data.order.id);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('orderWasCancelledSuccessfully'),
              messageType: 'success'
            }
          )
        );
      } catch {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('errorWhileCancellingTheOrder'),
              messageType: 'error'
            }
          )
        );
      }
    },

    async enableOrderFromMenu(data) {
      try {
        await this.enableOrder(data.order.id);
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('orderWasEnabledSuccessfully'),
              messageType: 'success'
            }
          )
        );
      } catch (error) {
        this.$root.$emit(
          'open-status-message-snackbar',
          Object.assign(
            {},
            {
              message: this.$t('errorWhileEnablingTheOrder'),
              messageType: 'error'
            }
          )
        );
      }
    },

    hasSpareParts(order) {
      let hasSpareParts = false;
      if (order.detail && order.detail.item) {
        if (order.detail.item.spareParts.length > 0) {
          hasSpareParts = true;
        }
      }
      return hasSpareParts;
    },

    openContextMenu(event, order) {
      this.$root.$emit('open-context-menu', {
        actions: [
          {
            label: this.$t('editOrder'),
            action: this.editOrder,
            icon: 'mdi-pencil-outline',
            show:
              this.isOrderDifferentToLastState(order) &&
              this.isAdmin() &&
              !this.isOrderCancelled(order),
            isAction: true
          },
          /*{
            label: this.$t('deleteOrder'),
            action: this.deleteOrder,
            icon: 'mdi-delete',
            show:
              this.isOrderDifferentToLastState(order) &&
              (this.isAdmin() || this.isCashier()) &&
              !this.isOrderCancelled(order),
            isAction: true
          },*/
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('receiveSpareParts'),
            action: this.receiveSpareParts,
            icon: 'mdi-book-check-outline',
            show:
              (this.isAdmin() || this.isSupervisor()) &&
              this.hasSpareParts(order) &&
              !this.isOrderCancelled(order) &&
              this.isOrderDifferentToLastState(order),
            isAction: true
          },
          {
            label: this.$t('assignWorker'),
            action: this.assignWorker,
            icon: 'mdi-account-hard-hat-outline',
            show:
              this.isOrderDifferentToLastState(order) &&
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(order),
            isAction: true
          },
          {
            label: this.$t('manageJob'),
            action: this.openManageJobsModal,
            icon: 'mdi-briefcase-check-outline',
            show:
              (this.isAdmin() || this.isSupervisor()) &&
              !this.isOrderCancelled(order) &&
              this.isOrderDifferentToLastState(order),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('printOrder'),
            action: this.openConfirmationModalPrintOrder,
            icon: 'mdi-printer-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(order),
            isAction: true
          },
          {
            label: this.$t('printSpareParts'),
            action: this.isAbleToPrintSpareParts(order)
              ? this.printSparePart
              : null,
            icon: 'mdi-printer-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(order),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('deliverToCustomer'),
            action: this.deliverToCustomerModal,
            icon: 'mdi-account-arrow-left-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(order) &&
              this.isOrderDifferentToLastState(order),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('makePayment'),
            action: this.makePaymentModal,
            icon: 'mdi-credit-card-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(order) &&
              !this.isAllPaymentComplete(order),
            isAction: true
          },
          {
            show: true,
            isAction: false
          },
          {
            label: this.$t('cancelCredit'),
            action: this.openConfirmationModalCancelCredit,
            icon: 'mdi-card-bulleted-off-outline',
            show:
              (this.isAdmin() || this.isSupervisor() || this.isCashier()) &&
              !this.isOrderCancelled(order) &&
              this.isOrderLoan(order),
            isAction: true
          },
          {
            label: this.$t('enableOrder'),
            action: this.enableOrderFromMenu,
            icon: 'mdi-check-outline',
            show: this.isOrderCancelled(order),
            isAction: true
          },
          {
            label: this.$t('cancelOrder'),
            action: this.cancelOrderFromMenu,
            icon: 'mdi-cancel',
            show: !this.isOrderCancelled(order),
            isAction: true
          }
        ],
        data: { order },
        positionX: event.clientX,
        positionY: event.clientY
      });
    },

    openConfirmationModalPrintOrder(data) {
      this.$refs.printConfirmationModal.open(data.order.id);
    },

    openConfirmationModal(message, isOverOtherModal, title) {
      this.$refs.confirmationModal.open(
        Object.assign({}, { message, isOverOtherModal, title })
      );
    },

    isAllServicesFinished(order) {
      const { detail } = order;
      let totalServicesCounter = 0;
      let totalFinishedServicesCounter = 0;
      if (detail && detail.services) {
        detail.services.forEach(service => {
          if (service.subServices.length === 0) {
            if (service.isFinished !== undefined) {
              if (service.isFinished === true) {
                totalFinishedServicesCounter++;
              }
            }
            totalServicesCounter++;
          } else {
            service.subServices.forEach(subService => {
              if (subService.isFinished !== undefined) {
                if (subService.isFinished === true) {
                  totalFinishedServicesCounter++;
                }
              }
              totalServicesCounter++;
            });
          }
        });

        return totalServicesCounter === totalFinishedServicesCounter;
      }
    },

    openManageJobsModal(data) {
      this.$refs.manageJobListModal.open(data.order);
    },

    makePaymentModal(data) {
      let isFromBoard = false;
      this.$refs.deliverOptionModal.open(
        isFromBoard,
        true,
        Object.assign({}, data.order)
      );
    },

    async deliverToCustomerModal(data) {
      let isFromBoard = false;
      let orderToDeliver = JSON.parse(JSON.stringify(data.order));
      orderToDeliver.orderStatusId = this.getFinalOrderStatus.id;
      this.$refs.deliverOptionModal.open(isFromBoard, false, orderToDeliver);
    },

    confirmationModalConfirmAction() {
      switch (this.isConfirmModalAction) {
        case CANCEL_CREDIT:
          this.updateIsLoanOfAnOrderAsFalse(this.orderToContextMenu);
          break;
        default:
          break;
      }
      this.isConfirmModalAction = null;
      this.orderToContextMenu = null;
    },

    openConfirmationModalCancelCredit(data) {
      this.isConfirmModalAction = CANCEL_CREDIT;
      this.orderToContextMenu = data.order;
      let message = this.$t('cancelOrderMessage');
      let isOverOtherModal = false;
      let title = this.$t('cancelCredit');
      this.openConfirmationModal(message, isOverOtherModal, title);
    },

    isOrderLoan(order) {
      return order.isLoan;
    },

    isOrderDifferentToLastState(order) {
      return order.orderStatus.isFinal === false;
    },

    setListOrderStatusesFilter() {
      this.listOrderStatusesFilter = [];

      let initialOrderStatus = this.getInitialOrderStatus;
      this.listOrderStatusesFilter.push({
        id: initialOrderStatus.id,
        value: FIVE_LAST_DAYS,
        label: this.$t('fiveLastDays')
      });

      this.getAllInProgressOrderStatuses.forEach(os => {
        this.listOrderStatusesFilter.push({
          id: os.id,
          value: FIVE_LAST_DAYS,
          label: this.$t('fiveLastDays')
        });
      });
    },

    makeFilter(orderStatusId, selectedOption) {
      let selected = this.getSelectedFilterForEachColumn(orderStatusId);
      selected.value = selectedOption.value;
      selected.label = selectedOption.label;
      this.retrieveFilteredBusinessOrdersByOrderStatus({
        businessId: this.businessId,
        orderStatusId,
        filterBy: selected.value
      });
    },

    getLabelSelectedFilter(orderStatusId) {
      return this.getSelectedFilterForEachColumn(orderStatusId)?.label;
    },

    getSelectedFilterForEachColumn(orderStatusId) {
      var foundIndex = this.listOrderStatusesFilter.findIndex(
        x => x.id == orderStatusId
      );
      return this.listOrderStatusesFilter[foundIndex];
    },

    openBoardFilterMenu(event, orderStatusID) {
      this.$refs.boardFilterMenu.open(
        event,
        orderStatusID,
        this.getSelectedFilterForEachColumn(orderStatusID)
      );
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
