import Vue from 'vue';
import VueRouter from 'vue-router';
import ClientsList from '../views/client/clients-list/ClientsList.vue';
import PartsList from '../views/part/parts-list/PartsList.vue';
import SparePartsList from '../views/spare-part/spare-parts-list/SparePartsList.vue';
import RectifierOrdersList from '../views/order/orders-list/rectifier-orders-list/RectifierOrdersList.vue';
import RectifierOrderStatusesList from '../views/order-status/rectifier-order-statuses-list/RectifierOrderStatusesList.vue';
import Login from '../views/login/Login.vue';
import RectifierOrdersBoard from '../views/order/orders-boards/rectifier-board/RectifierOrdersBoard.vue';
import ServiceList from '../views/service/service-list/ServiceList.vue';
import UnitOfMeasuresList from '../views/spare-part/unit-of-measures-list/UnitOfMeasuresList.vue';
import RectifierHistoricalOrdersList from '../views/order/orders-list/rectifier-historical-orders-list/RectifierHistoricalOrdersList.vue';
import WorkersList from '../views/worker/workers-list/WorkersList.vue';
import CylindersList from '../views/cylinders/cylinders-list/CylindersList.vue';
import ClientsReport from '../views/reports/clients-report/ClientsReport.vue';
import BrandsReport from '../views/reports/brands-report/BrandsReport.vue';
import ServicesReport from '../views/reports/services-report/ServicesReport.vue';
import ServicesMoneyReport from '../views/reports/service-money-report/ServicesMoneyReport.vue';
import SparePartsReport from '../views/reports/spare-parts-report/SparePartsReport.vue';
import FinancialReport from '../views/reports/financial-report/FinancialReport.vue';
import WorkersReport from '../views/reports/workers-report/WorkersReport.vue';
import DebtorsReport from '../views/reports/debtors-report/DebtorsReport.vue';
import UnpickedOrdersReport from '../views/reports/unpicked-orders-report/UnpickedOrdersReport.vue';
import CalendarOrders from '../views/calendar-orders/CalendarOrders.vue';

import Settings from '../views/settings/Settings.vue';

Vue.use(VueRouter);

function getAdminIdFromLocalStorage() {
  let roles = JSON.parse(window.localStorage.getItem('roles'));
  return roles.find(r => r.roleName === 'Administrator').id;
}

function getSupervisorIdFromLocalStorage() {
  let roles = JSON.parse(window.localStorage.getItem('roles'));
  return roles.find(r => r.roleName === 'Supervisor').id;
}

function getCashierIdFromLocalStorage() {
  let roles = JSON.parse(window.localStorage.getItem('roles'));
  return roles.find(r => r.roleName === 'Cashier').id;
}

function isAdmin() {
  const userRoleId = JSON.parse(window.localStorage.getItem('userInfo'));
  return userRoleId.roleId === getAdminIdFromLocalStorage();
}

function isSupervisor() {
  const userRoleId = JSON.parse(window.localStorage.getItem('userInfo'));
  return userRoleId.roleId === getSupervisorIdFromLocalStorage();
}

function isCashier() {
  const userRoleId = JSON.parse(window.localStorage.getItem('userInfo'));
  return userRoleId.roleId === getCashierIdFromLocalStorage();
}

function isPathAccessForAdmin(path) {
  return (
    path === '/clients' ||
    path === '/parts' ||
    path === '/spare-parts' ||
    path === '/services' ||
    path === '/rectifier-order-statuses' ||
    path === '/unit-of-measures' ||
    path === '/workers' ||
    path === '/cylinders' ||
    path === '/clients-report' ||
    path === '/brands-report' ||
    path === '/service-report' ||
    path === '/service-money-report' ||
    path === '/spare-parts-report' ||
    path === '/financial-report' ||
    path === '/order-by-engine-brand-number-cylinders-report' ||
    path === '/workers-report' ||
    path === '/debtors-report' ||
    path === '/unpicked-orders-report' ||
    path === '/rectifier-orders' ||
    path === '/rectifier-orders-board' ||
    path === '/rectifier-historical-orders' ||
    path === '/calendar-orders' ||
    path === '/settings'
  );
}

function isPathAccessForSupervisor(path) {
  return (
    path === '/rectifier-orders' ||
    path === '/rectifier-orders-board' ||
    path === '/rectifier-historical-orders' ||
    path === '/calendar-orders' ||
    path === '/settings'
  );
}

function isPathAccessForCashier(path) {
  return (
    path === '/rectifier-orders' ||
    path === '/rectifier-historical-orders' ||
    path === '/settings'
  );
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/clients',
    name: 'ClientsList',
    component: ClientsList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/parts',
    name: 'PartsList',
    component: PartsList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/spare-parts',
    name: 'SparePartsList',
    component: SparePartsList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/services',
    name: 'ServiceList',
    component: ServiceList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/rectifier-orders',
    name: 'RectifierOrdersList',
    component: RectifierOrdersList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/rectifier-order-statuses',
    name: 'RectifierOrderStatusesList',
    component: RectifierOrderStatusesList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/rectifier-orders-board',
    name: 'RectifierOrdersBoard',
    component: RectifierOrdersBoard,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/unit-of-measures',
    name: 'UnitOfMeasuresList',
    component: UnitOfMeasuresList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/rectifier-historical-orders',
    name: 'RectifierHistoricalOrdersList',
    component: RectifierHistoricalOrdersList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/workers',
    name: 'WorkersList',
    component: WorkersList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/cylinders',
    name: 'CylindersList',
    component: CylindersList,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/clients-report',
    name: 'ClientsReport',
    component: ClientsReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/brands-report',
    name: 'BrandsReport',
    component: BrandsReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/service-report',
    name: 'ServicesReport',
    component: ServicesReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/service-money-report',
    name: 'ServicesMoneyReport',
    component: ServicesMoneyReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/spare-parts-report',
    name: 'SparePartsReport',
    component: SparePartsReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/financial-report',
    name: 'FinancialReport',
    component: FinancialReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/workers-report',
    name: 'WorkersReport',
    component: WorkersReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/debtors-report',
    name: 'DebtorsReport',
    component: DebtorsReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/unpicked-orders-report',
    name: 'UnpickedOrdersReport',
    component: UnpickedOrdersReport,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      title: 'Rectificadora'
    }
  },
  {
    path: '/calendar-orders',
    name: 'CalendarOrders',
    component: CalendarOrders,
    meta: {
      title: 'Rectificadora'
    }
  }
];

const router = new VueRouter({ mode: 'history', routes });

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token');
  document.title = to.meta.title;
  if (to.fullPath === '/login') next();
  else {
    if (token) {
      if (from.path === '/' && to.path === '/') {
        if (isAdmin()) next('/clients');
        else {
          next('/rectifier-orders');
        }
      } else {
        if (
          (isAdmin() && isPathAccessForAdmin(to.path)) ||
          (isSupervisor() && isPathAccessForSupervisor(to.path)) ||
          (isCashier() && isPathAccessForCashier(to.path))
        ) {
          next();
        } else {
          if (isAdmin()) {
            next('/clients');
          }
          if (isSupervisor() || isCashier()) {
            next('/rectifier-orders');
          }
        }
      }
    } else next('/login');
  }
});

export default router;
