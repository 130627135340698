import { render, staticRenderFns } from "./QualityAssuranceModal.vue?vue&type=template&id=74e0204b&scoped=true"
import script from "./QualityAssuranceModal.vue?vue&type=script&lang=js"
export * from "./QualityAssuranceModal.vue?vue&type=script&lang=js"
import style0 from "./QualityAssuranceModal.vue?vue&type=style&index=0&id=74e0204b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e0204b",
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fcomponents%2Forder%2Fquality-assurance-modal%2FQualityAssuranceModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports