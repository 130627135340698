<i18n src="./resources/locales.json"></i18n>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title>{{ $t('receiveSpareParts') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item>
            <v-list-item-content
              class="ml-n11"
              v-if="order !== null && order.detail && order.detail.item"
            >
              <ul
                class="mt-n4"
                v-for="(sparePart, i) in order.detail.item.spareParts"
                :key="i"
              >
                <v-checkbox
                  color="primary"
                  v-model="sparePart.received"
                  hide-details
                >
                  <template v-slot:label>
                    <span>
                      <span class="font-weight-bold"
                        >{{ sparePart.quantity }}x</span
                      >
                      {{ renderSparePart(sparePart) }}<br /><span
                        class="font-weight-bold primary--text"
                        >{{ renderReceivedSparePart(sparePart) }}</span
                      >
                      <span class="font-weight-bold">{{
                        renderReceivedDateTimeOfSparePart(sparePart)
                      }}</span>
                    </span>
                  </template>
                </v-checkbox>
                <v-text-field
                  v-if="sparePart.received"
                  :label="$t('brand')"
                  v-model="sparePart.brandName"
                >
                </v-text-field>
              </ul>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" @click="save"> {{ $t('save') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  name: 'ReceiveSparePartsModal',

  data() {
    return {
      dialog: false,
      order: null
    };
  },

  created() {
    this.$root.$on('open-receive-spare-parts-modal', this.open);
  },

  beforeDestroy() {
    this.$root.$off('open-receive-spare-parts-modal');
  },

  methods: {
    ...mapActions('orders', ['updateOrder', 'moveToComplete']),

    open(orderToUpsert) {
      this.order = JSON.parse(JSON.stringify(orderToUpsert));
      this.dialog = true;
    },

    close() {
      this.order = null;
      this.dialog = false;
    },

    async save() {
      this.order.detail.item.spareParts = this.assignReceivedDateOfSpareParts(
        this.order.detail.item.spareParts
      );
      if (
        this.order.orderStatus.isInitial === true &&
        this._isAllSparePartsReceived(this.order.detail.item.spareParts) &&
        this.verifyAllAssignedServices()
      ) {
        try {
          await this.updateOrder(this.order);
          await this.moveToComplete(this.order);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('sparePartsReceivedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileReceivingSpareParts'),
                messageType: 'error'
              }
            )
          );
        }
      } else {
        try {
          await this.updateOrder(this.order);
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('sparePartsReceivedSuccessfully'),
                messageType: 'success'
              }
            )
          );
        } catch (error) {
          this.$root.$emit(
            'open-status-message-snackbar',
            Object.assign(
              {},
              {
                message: this.$t('errorWhileReceivingSpareParts'),
                messageType: 'error'
              }
            )
          );
        }
      }
      this.close();
    },

    renderSparePart(sparePart) {
      return ` ${sparePart.name} (${sparePart.measure} ${sparePart.unitOfMeasure})`;
    },

    renderReceivedSparePart(sparePart) {
      if (sparePart.received) return this.$t('received');
      else return this.$t('onHold');
    },

    renderReceivedDateTimeOfSparePart(sparePart) {
      if (
        sparePart.received &&
        sparePart.receivedDate !== undefined &&
        sparePart.receivedDate !== null
      ) {
        return ` (${moment(sparePart.receivedDate).format(
          'DD-MM-YYYY'
        )} - ${moment(sparePart.receivedDate).format('HH:mm')})`;
      }
    },

    assignReceivedDateOfSpareParts(spareParts) {
      let sparePartsWithDate = [];
      sparePartsWithDate = spareParts.map(sparePart => ({
        ...sparePart,
        receivedDate: this._getReceivedSparePartDate(sparePart)
      }));
      return sparePartsWithDate;
    },

    _getReceivedSparePartDate(sparePart) {
      if (sparePart.received) {
        return moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
      } else {
        return null;
      }
    },

    verifyAllAssignedServices() {
      let totalServices = 0;
      this.order.detail.services.forEach(service => {
        if (service.subServices.length === 0) {
          totalServices += 1;
        } else {
          totalServices += service.subServices.length;
        }
      });
      return totalServices === this.order.assignedServices.length;
    },

    _isAllSparePartsReceived(spareParts) {
      return spareParts.every(sparePart => sparePart.received === true);
    }
  }
};
</script>
