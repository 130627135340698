export const BUSINESS_ID_RECTIFICATION = '8880513e-d782-42c5-a026-3b45c4f67123';
export const DEFAULT_CURRENCY = 'BOB';
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const WORKER_ROLE_ID = '9234F19B-2544-4178-A6DA-224A13E8734E';
export const ADMIN_ROLE_ID = '1D5C67AE-D2C7-489A-B853-F5F582D1A174';
export const SUPERVISOR_ROLE_ID = '6EF7E1E3-9BF8-45E9-9D79-9EF93BD31861';
export const CASHIER_ROLE_ID = 'F74D8CDA-BEDE-4552-BA4D-7D4775157A05';
export const ALL_OPTIONS = 'allOptions';
export const ALL_SUBSERVICES = 'allSubServices';
export const HISTORICAL = 'historical';
export const CURRENT = 'current';
export const FIRTS_ORDER_STATE_POSITION = -1;
export const SECOND_ORDER_STATE_POSITION = 0;
export const THIRD_ORDER_STATE_POSITION = 1;
export const CREDIT_WITH_MISSING_PAYMENTS = 'creditWithMissingPayments';
export const ALL = 'all';
export const FIVE_LAST_DAYS = '5LastDays';
export const TODAY = 'today';
export const WORKERS = 'workers';
export const ORDERS = 'orders';
