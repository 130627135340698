<i18n src="./resources/locales.json"></i18n>

<template>
  <v-expansion-panels accordion flat tile>
    <v-expansion-panel>
      <v-expansion-panel-header class="px-0">
        <p class="mb-0 font-weight-bold advance-payments-text">
          {{ $t('advancePayments') }}
          ({{ getTotalPayments() | currency('BOB') }})
        </p>
      </v-expansion-panel-header>

      <v-expansion-panel-content
        class="expansion-panel-content-advance-payments"
      >
        <v-data-table
          :headers="headersOrderPayments"
          :items="order.paymentList"
          hide-default-footer
          :no-data-text="$t('thereIsNotAdvancePayments')"
          dense
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ getFormatDate(item.date) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ item.amount | currency('BOB') }}
          </template>
          <template v-slot:[`item.balance`]="{ item, index }">
            {{ getBalance(index, item.amount) | currency('BOB') }}
          </template>
          <template v-slot:[`footer`]>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="text-end align-end">
                <p class="font-weight-bold my-0 advance-payments-text">
                  {{ $t('total') }}: ({{
                    getTotalPayments() | currency('BOB')
                  }})
                </p>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import moment from 'moment';
import OrderMixin from '../../../mixins/OrderMixin.js';

export default {
  name: 'AdvancePayments',

  mixins: [OrderMixin],

  props: ['order', 'servicesDiscount', 'sparePartsDiscount'],

  computed: {
    headersOrderPayments() {
      return [
        {
          text: `${this.$t('date')}/${this.$t('hour')}`,
          value: 'date',
          align: 'start'
        },
        { text: this.$t('amount'), value: 'amount', align: 'end' },
        { text: this.$t('balance'), value: 'balance', align: 'end' }
      ];
    }
  },

  methods: {
    getTotalPayments() {
      return parseInt(this.order.totalPayments).toFixed(2);
    },

    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },

    getBalance(index, pay) {
      let totalPayments = 0;
      let newPayments = this.order.paymentList.slice();
      newPayments = newPayments.slice(0, index);
      totalPayments =
        this.calculateTotalServicesSpareParts(
          this.order,
          this.servicesDiscount,
          this.sparePartsDiscount
        ) - newPayments.reduce((sum, el) => sum + el.amount, 0);
      return totalPayments - pay;
    }
  }
};
</script>

<style scoped>
@import './ScopedStyles.css';
</style>
