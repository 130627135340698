import { render, staticRenderFns } from "./OrderDeliverOptionModal.vue?vue&type=template&id=24d26bb4&scoped=true"
import script from "./OrderDeliverOptionModal.vue?vue&type=script&lang=js"
export * from "./OrderDeliverOptionModal.vue?vue&type=script&lang=js"
import style0 from "./OrderDeliverOptionModal.vue?vue&type=style&index=0&id=24d26bb4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d26bb4",
  null
  
)

/* custom blocks */
import block0 from "./resources/locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fgithub%2Fworkspace%2Fsrc%2Fcomponents%2Forder%2Forder-deliver-option-modal%2FOrderDeliverOptionModal.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports