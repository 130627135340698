<i18n src="./resources/locales.json"></i18n>

<template>
  <v-container>
    <h1 class="ml-3 primary--text">{{ $t('financialReport') }}</h1>
    <v-row
      class="tool-bar mt-10 ml-16 mb-n12 mr-16"
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <div v-if="!isPendingBillsReport()">
        <v-menu
          ref="calendarMenu"
          v-model="calendarMenu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              :label="$t('dates')"
              prepend-inner-icon="mdi-calendar"
              background-color="primaryVariant2"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              dense
              flat
              class="search-bar"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            scrollable
            show-adjacent-months
            no-title
            :max="currentDate"
            :locale="$i18n.locale"
            color="primary"
            @change="verifyRangeChangesDate"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendarMenu = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" @click="savePickedDate">
              {{ $t('ok') }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>

      <v-menu
        v-model="filterMenu"
        :close-on-content-click="false"
        :nudge-width="100"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-3" color="primary" dark v-bind="attrs" v-on="on">
            mdi-filter-variant
          </v-icon>
        </template>

        <v-card>
          <v-card-title>{{ $t('filters') }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedFinantialReportFilterOption">
              <v-radio
                v-for="(l, i) in filterOptions"
                :key="i"
                :label="l.label"
                :value="l.value"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-subheader>{{ $t('showing') }}</v-subheader>
      <v-subheader> - {{ renderFilterOption() }}</v-subheader>

      <v-spacer></v-spacer>

      <v-menu
        ref="printOptions"
        v-model="printOptions"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            {{ $t('printReport') }}
          </v-btn>
        </template>

        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{ $t('columnsToPrint') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <template>
            <v-container fluid>
              <v-checkbox
                v-model="printOrderNumber"
                :label="$t('orderNumber')"
              ></v-checkbox>
              <v-checkbox
                v-model="printCredit"
                :label="$t('credit')"
              ></v-checkbox>
              <v-checkbox
                v-model="printDebit"
                :label="$t('debit')"
              ></v-checkbox>
            </v-container>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="printOptions = false">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              @click="
                printOptions = false;
                printReport();
              "
            >
              {{ $t('accept') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-row>

    <v-data-table
      :headers="headers"
      class="elevation-1 ma-16"
      :items="finantialReportToShow.financialOrderReports"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50, 100],
        itemsPerPageText: $t('rowsPerPage')
      }"
      :items-per-page="10"
      :item-class="itemRowBackground"
    >
      <template v-slot:[`item.orderNumber`]="{ item }">
        {{ numberFormatter(item.orderNumber) }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ convertDate(getFormatDate(item.date)) }}
      </template>
      <template v-slot:[`item.totalAmmountOfOrder`]="{ item }">
        <span
          v-if="
            totalOrderIsDeliveredOrCancelledOrCretid(item.totalAmmountOfOrder)
          "
        >
          <span class="p-line-through">
            {{
              getTotalOrderNumber(item.totalAmmountOfOrder) | currency('BOB')
            }}
          </span>
          <span>
            {{ getTotalOrderWord(item.totalAmmountOfOrder) }}
          </span>
        </span>
        <span v-else>
          {{ item.totalAmmountOfOrder | currency('BOB') }}
        </span>
      </template>
      <template v-slot:[`item.credit`]="{ item }">
        <span>
          <span v-if="item.isDebitCard"> * </span>
          {{ item.credit | currency('BOB') }}
        </span>
      </template>
      <template v-slot:[`item.debit`]="{ item }">
        {{ item.debit | currency('BOB') }}
      </template>
      <template v-slot:no-data>
        <p>{{ $t('noDataMessage') }}</p>
      </template>
    </v-data-table>

    <div class="mt-10 ml-16 mb-n12 mr-16">
      <v-divider class="mb-3"></v-divider>

      <v-layout justify-end row wrap class="px-3">
        <v-flex class="text-end">
          <p class="font-weight-bold my-0">
            {{ $t('statementOfAccounts') }}
            ({{ dateRangeText }})
          </p>
        </v-flex>
      </v-layout>

      <v-layout justify-end row wrap class="px-3">
        <v-flex xs6 sm4 md3 lg2 xl3 class="text-end">
          <span>
            {{ $t('totalServices') }}
          </span>
        </v-flex>
        <v-flex xs6 sm2 md2 lg1 xl1 class="text-end">
          <span>
            {{
              finantialReportToShow.totalAmmountOfServicesAndSpareParts
                | currency('BOB')
            }}
          </span>
        </v-flex>
      </v-layout>

      <v-layout justify-end row wrap class="px-3">
        <v-flex xs6 sm4 md3 lg2 xl3 class="text-end">
          <span>
            {{ $t('totalPaidWithCard') }}
          </span>
        </v-flex>
        <v-flex xs6 sm2 md2 lg1 xl1 class="text-end">
          <span>
            {{ finantialReportToShow.totalPaidWithCard | currency('BOB') }}
          </span>
        </v-flex>
      </v-layout>

      <v-layout justify-end row wrap class="px-3">
        <v-flex xs6 sm4 md3 lg2 xl3 class="text-end">
          <span>
            {{ $t('totalPaidWithCash') }}
          </span>
        </v-flex>
        <v-flex xs6 sm2 md2 lg1 xl1 class="text-end">
          <span>
            {{ finantialReportToShow.totalPaidWithCash | currency('BOB') }}
          </span>
        </v-flex>
      </v-layout>

      <v-layout justify-end row wrap class="px-3">
        <v-flex xs6 sm4 md3 lg2 xl3 class="text-end">
          <span>
            {{ $t('totalIncome') }}
          </span>
        </v-flex>
        <v-flex xs6 sm2 md2 lg1 xl1 class="text-end">
          <span>
            {{ finantialReportToShow.totalRevenue | currency('BOB') }}
          </span>
        </v-flex>
      </v-layout>

      <v-layout justify-end row wrap class="px-3">
        <v-flex xs6 sm4 md3 lg2 xl3 class="text-end">
          <span>
            {{ $t('totalReceivable') }}
          </span>
        </v-flex>
        <v-flex xs6 sm2 md2 lg1 xl1 class="text-end">
          <span>
            {{ finantialReportToShow.totalToCollect | currency('BOB') }}
          </span>
        </v-flex>
      </v-layout>

      <v-divider class="mt-3"></v-divider>
    </div>

    <LoadingMask :isLoading="getIsFinancialReportLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingMask from '@/components/reusable-components/loading-mask/LoadingMask.vue';
import color from '../../../mixins/color.js';
import moment from 'moment';
import { ALL_OPTIONS } from '@/constants/ConfigurationConstants';
import { EXPENSES, INCOME, PENDING_BILLS } from '@/constants/ReportConstants';

export default {
  name: 'FinancialReport',

  components: {
    LoadingMask
  },

  mixins: [color],

  data: () => ({
    dates: [],
    menu: false,
    printOptions: false,
    printOrderNumber: true,
    printCredit: true,
    printDebit: true,
    currentDate: moment().format('YYYY-MM-DD'),
    calendarMenu: false,
    filterMenu: false,
    selectedFinantialReportFilterOption: ALL_OPTIONS
  }),

  computed: {
    ...mapGetters('reports', ['finantialReport', 'pendingBillsReport']),
    ...mapGetters('loading', ['getIsFinancialReportLoading']),

    dateRangeText() {
      return this.dates.map(d => this.convertDate(d)).join(' - ');
    },

    headers() {
      return [
        {
          text: this.$t('date'),
          value: 'date',
          align: 'start',
          sortable: true
        },
        {
          text: this.$t('orderNumber'),
          value: 'orderNumber',
          align: 'start',
          sortable: true
        },
        {
          text: this.$t('clientName'),
          value: 'clientName',
          align: 'start',
          sortable: true
        },
        {
          text: this.$t('totalOrder'),
          value: 'totalAmmountOfOrder',
          align: 'end',
          sortable: true
        },
        {
          text: this.$t('credit'),
          value: 'credit',
          align: 'end',
          sortable: true
        },
        {
          text: this.$t('debit'),
          value: 'debit',
          align: 'end',
          sortable: true
        }
      ];
    },

    filterOptions() {
      return [
        {
          value: ALL_OPTIONS,
          label: this.$t('allOptions')
        },
        {
          value: INCOME,
          label: this.$t('income')
        },
        {
          value: EXPENSES,
          label: this.$t('expenses')
        },
        {
          value: PENDING_BILLS,
          label: this.$t('pendingBills')
        }
      ];
    },

    finantialReportToShow() {
      switch (this.selectedFinantialReportFilterOption) {
        case INCOME:
          return this.getOnlyIncome();
        case EXPENSES:
          return this.getOnlyExpenses();
        case PENDING_BILLS:
          return this.pendingBillsReport;
        default:
          return this.finantialReport;
      }
    }
  },

  created() {
    this.createDates();
    this.getFinancialReport();
    this.retrieveAllPendingBillsReports();
  },

  methods: {
    ...mapActions('reports', [
      'retrieveAllFinancialReports',
      'retrieveAllPendingBillsReports'
    ]),

    savePickedDate() {
      this.$refs.calendarMenu.save(this.dates);
      this.getFinancialReport();
    },

    getFinancialReport() {
      const [first, second] = this.dates;
      let parameters = {};
      if (first) parameters.fromDate = moment(first).format();
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      else parameters.toDate = this.setDateUntilLastMinute(first);
      this.retrieveAllFinancialReports(parameters);
    },

    convertDate(date) {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    verifyRangeChangesDate() {
      const [first, second] = this.dates;
      if (first !== undefined && second !== undefined) {
        if (moment(first).isSame(second)) this.dates = [first];
        if (moment(first).isAfter(second)) this.dates = [second, first];
      }
    },

    numberFormatter(orderNumber) {
      return orderNumber.toString().length < 5
        ? this.numberFormatter('0' + orderNumber)
        : orderNumber;
    },

    createDates() {
      this.dates = [this.currentDate];
    },

    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    totalOrderIsDeliveredOrCancelledOrCretid(totalOrder) {
      return (
        totalOrder.includes('Entregado') ||
        totalOrder.includes('Cancelado') ||
        totalOrder.includes('A credito')
      );
    },

    getTotalOrderNumber(totalOrder) {
      return totalOrder.split(' ')[0];
    },

    getTotalOrderWord(totalOrder) {
      return totalOrder.split(/ (.+)/)[1];
    },

    setDateUntilLastMinute(date) {
      return moment(date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format();
    },

    itemRowBackground: function(item) {
      return item.totalAmmountOfOrder.includes('Cancelado') ||
        item.totalAmmountOfOrder.includes('Entregado')
        ? ''
        : item.credit > item.debit ||
          item.totalAmmountOfOrder.includes('A credito')
        ? 'style-2'
        : 'style-1';
    },

    printReport() {
      const [first, second] = this.dates;
      let parameters = {};
      if (first) parameters.fromDate = moment(first).format();
      if (second) parameters.toDate = this.setDateUntilLastMinute(second);
      else parameters.toDate = this.setDateUntilLastMinute(first);
      parameters.printOrderNumber = this.printOrderNumber;
      parameters.printCredit = this.printCredit;
      parameters.printDebit = this.printDebit;
      this.$root.$emit('print-financial-report-pdf', parameters);
    },

    setFilterOption() {
      if (this.selectedFinantialReportFilterOption === ALL_OPTIONS)
        this.getFinancialReport();
    },

    renderFilterOption() {
      switch (this.selectedFinantialReportFilterOption) {
        case INCOME:
          return this.$t('income');
        case EXPENSES:
          return this.$t('expenses');
        case PENDING_BILLS:
          return this.$t('pendingBills');
        default:
          return this.$t('allOptions');
      }
    },

    getOnlyExpenses() {
      let report = { ...this.finantialReport };
      report.financialOrderReports = report?.financialOrderReports?.filter(
        r => r.credit <= r.debit
      );
      return report;
    },

    getOnlyIncome() {
      let report = { ...this.finantialReport };
      report.financialOrderReports = report?.financialOrderReports?.filter(
        r => r.credit > r.debit || r.totalAmmountOfOrder.includes('A credito')
      );
      return report;
    },

    isPendingBillsReport() {
      return this.selectedFinantialReportFilterOption === PENDING_BILLS;
    }
  }
};
</script>
<style scoped>
@import './ScopedStyles.css';
</style>
